.Menu{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin: 0;
}

.Item{
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
}

.Item .item-text{
    padding: 5px;
    vertical-align: super;
}

.Item:hover{
    background-color: #dddddddd;
}

.Divisor {
    margin: 0;
}

.active .Item{
    background-color: #dddddddd;
}

@media screen and (max-width: 600px) {
    .Menu {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100%;
        min-width: 100%;
        height: 90px;
    }
}
