.container {
    display: flex;
    flex-flow: column;
}

.bp3-html-table{
    width: 100%;
}

.buttons button{
    margin-right: 5px;
}

.page-buttons{
    padding-bottom: 16px;
}

.page-buttons button{
    margin-right: 5px;
}
