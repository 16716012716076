.form{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.form > *{
    padding: 15px;
}

@media screen and (max-width: 1000px) {
    .form {
        flex-wrap: wrap;
    }
}


@media screen and (max-width: 600px) {
    .form {
        flex-direction: column;
        flex-wrap: wrap;

    }
}
