.App {
    min-width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
}

header{
    width: 100%;
    height: 60px;
}

header .bp3-navbar{
    height: 100%;
}

header .bp3-navbar-group{
    height: 100%;
}

section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 60px);
    height: calc(100% - 60px);
}

nav {
    border-right: 1px solid #ddd;
    display: inline-block;
    width: 160px;
    background-color: #394b59;
}

main {
    padding: 15px;
}

.bp3-dark a {
    color: white !important;
}

@media screen and (max-width: 600px) {
    section {
        flex-direction: column;
    }
    nav{
        width: 100%;
    }
    main > * {
        overflow-x: auto;
    }
}
